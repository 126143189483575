export const BASED_TICKER = 'BASED';
export const BSHARE_TICKER = 'BSHARE';
export const BBOND_TICKER = 'BBOND';
export const TOMB_TICKER = 'TOMB';
export const WFTM_TICKER = 'WFTM';
export const FTM_TICKER = 'FTM';
export const MAI_TICKER = 'MAI';
export const BASED_TOMB_LP_TICKER = 'BASED-TOMB-LP';
export const BSHARE_FTM_LP_TICKER = 'BSHARE-FTM-LP';
export const BASED_TOMB_TSWAP_LP_TICKER = 'BASED-TOMB-TSWAP-LP';
export const BASED_MAI_LP_TICKER = 'BASED-MAI-LP';
export const BASED_USDC_LP_TICKER = 'BASED-USDC-LP';
export const MOO_BASED_BASED_TOMB_TICKER = 'MOO-BASED-BASED-TOMB';
export const USDC_TICKER = 'USDC(MULTI)';
export const SMELT_TICKER = 'SMELT';
export const OBOL_TICKER = 'OBOL';
export const OBOL_FTM_LP_TICKER = 'OBOL-FTM-LP';
export const SMELT_FTM_LP_TICKER = 'SMELT-FTM-LP';
export const ONE_WAY_TICKET = 'ONE_WAY_TICKET';
export const WLIUH = 'WLIUH';
export const ZALUPA = 'ZALUPA';

export const SPOOKY_ROUTER_ADDR = '0xF491e7B69E4244ad4002BC14e878a34207E38c29';
export const BASED_ROUTER_ADDR = '0xaf2098E3AF3AC27a3a9362ACa2D883eFC36c7FAC';
export const TOMBSWAP_ROUTER_ADDR = '0x6D0176C5ea1e44b08D3dd001b0784cE42F47a3A7';
export const BASED_TOMB_ZAPPER_ROUTER_ADDR = '0x1b5114F935bc4D2A51012201cecfDf2509F24c33';
export const BSHARE_FTM_ZAPPER_ROUTER_ADDR = '0x6bcAb3512c3BA170419Bf1A0dC1B3eaeA242e72a';
export const PROFIT_DISTRIBUTION_ADDR = '0x8bf3552f84FF21056d92F5E7BC47EE613786D4fC';
export const TAX_OFFICE_ADDR = '0xcaf6C0FB8Bcb737C2D5D7e5Db615147a26091De1';
export const OBOL_SMELT_FTM_ZAPPER_ROUTER_ADDR = '0x56E1Fe11dEBEba9FFAA89df2440d0d3dd45A7D9D';

export const TICKER = {
  BASED: BASED_TICKER,
  BSHARE: BSHARE_TICKER,
  BBOND: BBOND_TICKER,
  TOMB: TOMB_TICKER,
  WFTM: WFTM_TICKER,
  FTM: FTM_TICKER,
  MAI: MAI_TICKER,
  USDC: USDC_TICKER,
  BASED_TOMB_LP: BASED_TOMB_LP_TICKER,
  SHARE_FTM_LP: BSHARE_FTM_LP_TICKER,
  BASED_TOMB_TSWAP_LP: BASED_TOMB_TSWAP_LP_TICKER,
  BASED_MAI_LP: BASED_MAI_LP_TICKER,
  BASED_USDC_LP: BASED_USDC_LP_TICKER,
  MOO_BASED_BASED_TOMB: MOO_BASED_BASED_TOMB_TICKER,
  SMELT: SMELT_TICKER,
  OBOL: OBOL_TICKER,
  OBOL_FTM_LP: OBOL_FTM_LP_TICKER,
  SMELT_FTM_LP: SMELT_FTM_LP_TICKER,
  BSHARE_FTM_LP: BSHARE_FTM_LP_TICKER,
  ONE_WAY_TICKET: ONE_WAY_TICKET,
  WLIUH: WLIUH,
  ZALUPA: ZALUPA
};
